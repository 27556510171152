import React from "react"
import Layout from "../components/layout.en"
import SEO from "../components/seo"
import SmallHeader from "../components/SmallHeader"
import { Container, Row, Col } from "reactstrap"
import TitleBlock from "../components/blocks/TitleBlock"
import { graphql, useStaticQuery } from "gatsby"

const Regulamin = () => {
  const images = useStaticQuery(graphql`
    {
      cover: file(
        name: { eq: "general-page-background" }
        relativeDirectory: { eq: "headers" }
      ) {
        id
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      <Layout>
        <SEO title="Regulamin" />

        <SmallHeader
          title="Regulamin"
          backgroundName={images.cover}
          hasLink={true}
        />

        <Container>
          <Row>
            <Col md="10" className="mr-auto ml-auto">
              <div className="section">
                <div className="text-center">
                  <TitleBlock
                    title="§1 Definicje"
                    headingLevel="h2"
                    extendedClasses="color-sea"
                  />
                </div>

                <div className="text-justify">
                  <p>
                    1. Właścicielem strony internetowej morska5.pl jest Anna
                    Łapiezo, z siedzibą w Szczecinie, ul. Górska 1, 71-737, NIP:
                    599 291 66 72, e-mail: rezerwacje@morska5.pl
                  </p>
                  <p>2. Użytkownik – osoba odwiedzająca stronę.</p>
                  <p>
                    3. Oferta – oferta zamieszczona przez właściciela na
                    stronie.
                  </p>
                  <p>
                    4. Dane osobowe – informacje dotyczące zidentyfikowanej lub
                    możliwej do zidentyfikowania osoby fizycznej, przykładowo:
                    nazwisko, adres, numer telefonu, data urodzenia, dane konta
                    bankowego, adres IP.
                  </p>
                </div>
              </div>

              <div className="section">
                <div className="text-center">
                  <TitleBlock
                    title="§1 Postanowienia ogólne"
                    headingLevel="h2"
                    extendedClasses="color-sea"
                  />
                </div>

                <div className="text-justify">
                  <p>
                    1. Operatorem strony internetowej morska5.pl jest
                    Właściciel.
                  </p>
                  <p>
                    2. Przeznaczenie strony: informacja o ofercie najmu
                    aprtamentów i pokoi właściciela, informacje kontaktowe.
                  </p>
                  <p>
                    3. Użytkownik jest zobowiązany do korzystania ze strony w
                    sposób zgodny z niniejszym Regulaminem, obowiązującym
                    prawem, ogólnymi zasadami korzystania z sieci Internet oraz
                    celami utworzenia strony, a w szczególności w sposób nie
                    naruszający praw osób trzecich oraz praw właściciela.
                  </p>
                  <p>
                    4. W przypadku stwierdzenia, że Użytkownik narusza pkt. 3
                    lub dopuszcza się działań opisanych w pkt 3, właściciel ma
                    prawo podjąć wszelkie działania prowadzące do naprawienia
                    poniesionej w związku z tym szkody.
                  </p>
                </div>
              </div>

              <div className="section">
                <div className="text-center">
                  <TitleBlock
                    title="§3 Odpowiedzialność za treść"
                    headingLevel="h2"
                    extendedClasses="color-sea"
                  />
                </div>

                <div className="text-justify">
                  <p>
                    1. Właściciel odpowiada jedynie za treści własne
                    zamieszczone na stronie.
                  </p>
                  <p>
                    2. Informacje zamieszczane przez właściciela na stronie są
                    na bieżąco aktualizowane oraz sporządzane w sposób staranny,
                    jednakże właściciel nie udziela gwarancji za prawidłowość,
                    aktualność, kompletność i stałą dostępność strony.
                  </p>
                  <p>
                    3. Właściciel udziela wiążących informacji, porad, zaleceń
                    lub wyjaśnień wyłącznie w ramach komunikacji indywidualnej.
                  </p>
                  <p>
                    4. Właściciel zastrzega sobie prawo zmiany oferty
                    internetowej, jej uzupełniania, skracania lub całkowitego
                    wycofania. Nie gwarantuje również, że treści oferty
                    internetowej są odpowiednie dla użytkownika i jego celów.
                  </p>
                  <p>
                    5. Na stronie mogą być umieszczone linki do adresów
                    internetowych podmiotów trzecich, na których treść
                    właściciel nie ma żadnego wpływu. Za treści znajdujące się
                    pod linkowanymi adresami odpowiada wyłącznie ich operator.
                  </p>
                  <p>
                    6. Właściciel zwraca się z prośbą o bezzwłoczne jego
                    powiadomienie, jeśli użytkownik, korzystając z linków
                    umieszczonych na stronie, trafi pod adresy, których treści
                    wydają się użytkownikowi wątpliwe. Usunięcie tego rodzaju
                    linków nastąpi bezzwłocznie po uzyskaniu informacji o
                    naruszeniu prawa przez treści adresów linkowanych.
                    Oświadczenie to obowiązuje dla wszystkich linków
                    znajdujących się na stronie właściciela.
                  </p>
                </div>
              </div>

              <div className="section">
                <div className="text-center">
                  <TitleBlock
                    title="§4 Obszar obowiązywania oferty"
                    headingLevel="h2"
                    extendedClasses="color-sea"
                  />
                </div>

                <div className="text-justify">
                  <p>
                    1. Oferta internetowa właściciela jest przeznaczona dla
                    obszaru Rzeczpospolitej Polskiej i została sporządzona na
                    podstawie polskiego prawa.
                  </p>
                  <p>
                    2. Właściciel nie ponosi żadnej odpowiedzialności za to, czy
                    oferta internetowa jest odpowiednia dla użytkowników z
                    innych państw, ma tam zastosowanie i jest prawnie
                    dopuszczalna.
                  </p>
                </div>
              </div>

              <div className="section">
                <div className="text-center">
                  <TitleBlock
                    title="§5 Prawa autorskie"
                    headingLevel="h2"
                    extendedClasses="color-sea"
                  />
                </div>

                <div className="text-justify">
                  <p>
                    1. Zawartość strony, w tym jej treść, kształt i konstrukcja
                    chroniona jest polskim i międzynarodowym prawem autorskim.
                    Prawa do wszystkich materiałów zamieszczonych na stronie
                    zastrzeżone są na rzecz właściciela bądź firm i osób
                    uprawnionych (np. partnerów handlowych, klientów).
                  </p>
                  <p>
                    2. Żadna część strony (m.in. teksty, dokumenty, pliki,
                    układy graficzne, grafiki) nie może być powielana lub
                    rozpowszechniana w jakiejkolwiek formie lub w jakikolwiek
                    sposób (elektroniczny lub mechaniczny) włącznie z
                    kopiowaniem, drukowaniem, fotokopiowaniem, nagrywaniem na
                    taśmy, dyskietki, płyty CD/DVD lub przy użyciu innych
                    systemów i środków, bez zgody właściciela, chyba że
                    regulamin stanowi inaczej.
                  </p>
                  <p>
                    3. Ściąganie i kopiowanie treści znajdujących się na stronie
                    jest dozwolone wyłącznie do prywatnego, niekomercyjnego
                    użytku, chyba że w danym przypadku określono inaczej.
                    Ustawianie hiperlinków na stronie, w szczególności deep
                    links, inline-links lub linków w technologii Frame, jest
                    dopuszczalne wyłącznie za uprzednią, pisemną zgodą
                    właściciela.
                  </p>
                  <p>
                    4. Naruszenie licencji i praw autorskich przez Użytkownika
                    skutkuje odpowiedzialnością prawną określoną w szczególności
                    w przepisach ustawy o prawie autorskim i prawach pokrewnych,
                    ustawy o zwalczaniu nieuczciwej konkurencji, przepisach
                    kodeku cywilnego lub przepisach prawa prasowego.
                  </p>
                </div>
              </div>

              <div className="section">
                <div className="text-center">
                  <TitleBlock
                    title="§6 Wskazówki techniczne"
                    headingLevel="h2"
                    extendedClasses="color-sea"
                  />
                </div>

                <div className="text-justify">
                  <p>
                    1. Przy cyfrowym przetwarzaniu danych mogą wystąpić błędy.
                  </p>
                  <p>
                    2. Używanie różnych przeglądarek oraz indywidualne
                    ustawienia oprogramowania mogą powodować różnice w
                    wyświetlaniu treści.
                  </p>
                </div>
              </div>

              <div className="section">
                <div className="text-center">
                  <TitleBlock
                    title="§7 Ochrona prywatności"
                    headingLevel="h2"
                    extendedClasses="color-sea"
                  />
                </div>

                <div className="text-justify">
                  <p>
                    1. Właściciel, będący administratorem danych osobowych w
                    rozumieniu ustawy z dnia 29 sierpnia 1997 r. o ochronie
                    danych osobowych (tj. Dz. U. z 2002 r., nr 101, poz. 926 ze
                    zm.), działa zgodnie z obowiązującymi przepisami oraz dba o
                    ochronę danych osobowych użytkowników.
                  </p>
                  <p>
                    2. Dane osobowe użytkowników są przetwarzane przez
                    właściciela w celu świadczenia usług, archiwizacji, a także
                    w celach marketingowych usług i produktów. Użytkownik
                    korzystając ze strony nie będzie otrzymywał informacji
                    marketingowych i handlowych jeśli nie wyrazi zgody.
                  </p>
                  <p>
                    3. Dane osobowe użytkowników są rejestrowane przy
                    korzystaniu ze strony, a ich przechowywanie służy do
                    wewnętrznych celów właściciela związanych z procesami
                    systemowymi statystycznymi dotyczącymi nazwy otwieranych
                    adresów www, używanej przeglądarki, systemu operacyjnego i
                    odwiedzanej domeny z datą i godziną wejścia, używanej
                    wyszukiwarki, nazwy ściąganych plików i adresu IP.
                    Przechowywanie danych osobowych może służyć także do
                    odpowiedzi na pytania użytkownika, realizacji zamówień
                    użytkownika, udziału w konkursach oraz w celu technicznej
                    administracji stroną.
                  </p>
                  <p>
                    4. Szczegółowe dane osobowe, takie jak nazwisko, adres
                    e-mail, adres domowy, nazwy użytkownika i hasła rejestrowane
                    są tylko wtedy, gdy są konieczne w ramach zapytania,
                    zamówienia lub rejestracji.
                  </p>
                  <p>
                    5. Użytkownikowi przysługuje prawo wglądu do własnych danych
                    osobowych, ich poprawiania, oraz skasowania. Skasowanie
                    danych osobowych nastąpi także w sytuacjach wymienionych w
                    obowiązujących przepisach ustawowych.
                  </p>
                  <p>
                    6. Właściciel nie ma prawa do sprzedaży danych osobowych
                    użytkowników.
                  </p>
                  <p>
                    7. Udostępnienie lub innego rodzaju przekazanie danych
                    osobowych użytkowników osobom trzecim nastąpi tylko wówczas,
                    gdy będzie konieczne w celu realizacji umowy lub do celów
                    rozliczeniowych albo ściągnięcia wynagrodzenia, lub wyrazi
                    na to zgodę użytkownik. Innego rodzaju wykorzystywanie tych
                    informacji jest niedozwolone. Ponadto właściciel może
                    przekazać anonimowe dane dotyczące użytkowania w celu badań
                    marketingowych. Identyfikacja użytkowników jest przy tym
                    wykluczona.
                  </p>
                  <p>
                    8. Na żądanie właściwego urzędu właściciel ma prawo ujawnić
                    dane w poszczególnych przypadkach, jeśli jest to konieczne
                    do celów przeprowadzenia postępowania karnego, zwalczania
                    zagrożeń przez policję, wypełniania zadań ustawowych
                    państwowych urzędów ochrony danych osobowych, państwowych
                    służb wywiadowczych lub kontrwywiadu wojskowego, albo do
                    dochodzenia praw własności intelektualnej.
                  </p>
                  <p>
                    9. Bazy danych właściciel zabezpieczył tak, aby dostęp do
                    znajdujących się w nich informacji był ograniczony. System
                    informatyczny jest chroniony zaporą sieciową. Dostęp do
                    danych osobowych otrzymują jedynie pracownicy właściciela w
                    celach wymienionych powyżej. Pracownicy właściciela są
                    przeszkoleni w odniesieniu do bezpieczeństwa i praktyk
                    ochrony danych osobowych.
                  </p>
                  <p>
                    10. Na stronie wykorzystywana jest technika ,,Cookies”. Są
                    to pliki tekstowe, zapisywane ze strony lokalnie w pamięci
                    przeglądarki na komputerze użytkownika. Ma to na celu
                    pobieranie danych statystycznych o korzystaniu ze strony a
                    także możliwość komfortowego użytkowania strony (np.
                    rozpoznawanie wybranej wersji językowej). Używane cookies
                    nie zawierają żadnych danych osobowych użytkowników.
                    Ustawione cookies użytkownik może w każdej chwili skasować
                    oraz korzystać ze strony bez używania cookies. W tym celu
                    należy skorzystać z opcji ,,wyłącz” znajdującej się w
                    ustawieniach przeglądarki, które znajdują się w Microsoft
                    Internet Explorer w punkcie menu ,,Dodatki/opcje
                    internetowe”, a w Mozilla Firerfox w punkcie
                    ,,Dodatki/ustawienia”. Szczegóły znajdują się z menu pomocy
                    właściwej przeglądarki. Wyłączenie lub wyświetlanie cookies
                    może mieć wpływ na funkcjonowanie strony.
                  </p>
                  <p>
                    11. Użytkownik może zapisać się na listę mailingową w celu
                    otrzymywania Newslettera. W takim przypadku właściciel
                    będzie musiał pobrać i zapisać adres e-mail użytkownika.
                    Adres e-mail użytkownika wykorzystany będzie wyłącznie w
                    celu wysyłania Newsletterów, informujących o aktualnej
                    ofercie. Użytkownik może w każdym czasie wyrejestrować się z
                    listy na stronie morska5.pl. W takim przypadku dane
                    użytkownika zostaną skasowane.
                  </p>
                  <p>
                    12. Użytkownik ma prawo uzyskania informacji o zapisanych
                    swoich danych, ich pochodzeniu i odbiorcy oraz celu
                    przetwarzania danych. W przypadku pytań dotyczących ochrony
                    danych mogą się Państwo zawsze zwrócić do nas za
                    pośrednictwem adresu rezerwacje@morska5.pl
                  </p>
                </div>
              </div>

              <div className="section">
                <div className="text-center">
                  <TitleBlock
                    title="§8 Postanowienia końcowe"
                    headingLevel="h2"
                    extendedClasses="color-sea"
                  />
                </div>

                <div className="text-justify">
                  <p>
                    1. Właściciel dokłada wszelkich starań w celu zapewnienia
                    prawidłowego funkcjonowania strony, jednakże nie zapewnia
                    gwarancji, że strona działać będzie bez awarii i przerw.{" "}
                  </p>
                  <p>
                    2. W razie jakichkolwiek zastrzeżeń co do działalności
                    strony, użytkownik może zgłaszać je właścicielowi poprzez
                    wysłanie e-maila na adres rezerwacje@morska5.pl
                  </p>
                  <p>
                    3. Właściciel zastrzega sobie prawo do zmiany niniejszego
                    regulaminu w każdym czasie. Aktualny regulamin będzie
                    zamieszczony na stronie.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  )
}

export default Regulamin
